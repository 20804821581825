<template>
  <div class="content_container">
    <el-breadcrumb
      separator-class="el-icon-arrow-right "
      class="titleNav"
    >

      <el-breadcrumb-item>
        <span @click="$router.push('/member-seek')">我的询价 </span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>发布询价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      ref="ruleForm"
      label-width="140px"
      class="ruleForm"
    >

      <el-form-item
        label="标 题"
        prop="name"
      >
        {{ruleForm.name}}
      </el-form-item>

      <el-form-item
        label="询价类型"
        prop="type"
      >
        <span v-if='ruleForm.type==1'> 现货/标准品</span>
        <span v-if='ruleForm.type==2'> 加工/定制品</span>

      </el-form-item>
      <el-form-item
        label="商家筛选"
        prop="cg_type"
      >
        <table class="tab">
          <tr
            v-if='ruleForm.cg_type==1'
            class="tr1"
          >
            <td colspan="3">公开询价</td>
          </tr>
          <tr
            class="tr1"
            v-if='ruleForm.cg_type==2'
          >
            <td colspan="3">定向询价</td>
          </tr>

          <tr v-if='ruleForm.cg_type==2'>
            <td> 主营品类:
              <span>{{ruleForm.cate_name}}</span></td>
            <td>
              所在地区:{{ruleForm.provice_name+'/'+ruleForm.city_name}}
            </td>
            <td> 经营模式：{{ ruleForm.model_name}}</td>

          </tr>
        </table>

      </el-form-item>

      <el-form-item label="物品清单">

        <table class='tab'>
          <tr class="tr1">
            <td style='width:30%'> 物品名称</td>
            <td style='width:10%'> 品牌</td>
            <td> 型号</td>
            <td> 需求数量</td>
            <td style='width:20%'> 其他说明</td>
          </tr>
          <tr
            v-for='(item,index) in ruleForm.get_inventory'
            :key='index'
          >
            <td>{{item.inventory_name}}</td>
            <td>{{item.inventory_brand}}</td>
            <td>{{item.inventory_no}}</td>
            <td>{{item.inventory_num}}</td>
            <td>{{item.inventory_explain}}</td>

          </tr>
        </table>

      </el-form-item>
      <el-form-item
        label="采购类型"
        prop="cai_type"
      >
        <span v-if='ruleForm.cai_type==1'> 单次采购</span>
        <span v-if='ruleForm.cai_type==2'> 协议采购(长期采购)</span>

      </el-form-item>
      <el-form-item
        label="发货要求"
        prop="fh_mark"
      >

        <span v-if='ruleForm.fh_mark==1'> 增值税专票</span>
        <span v-if='ruleForm.fh_mark==2'> 增值税普通发票</span>
        <span v-if='ruleForm.fh_mark==3'> 不用发票</span>

      </el-form-item>

      <el-form-item
        label="交货时间要求"
        prop="fh_date"
      >

        <span>{{ ruleForm.fh_date}}</span>

      </el-form-item>
      <el-form-item
        label=" 报价要求"
        prop="price_mark"
      >
        <span v-if='ruleForm.price_mark==1'> 只报商品价格</span>
        <span v-if='ruleForm.price_mark==2'> 报价含税</span>
        <span v-if='ruleForm.price_mark==3'> 报价需要包含运费</span>

      </el-form-item>
      <el-form-item
        label=" 询价有效期截止时间"
        prop="time"
      >
        {{ruleForm.date_e}}

      </el-form-item>
      <el-form-item
        label=" 联系方式"
        prop="phone"
      >
        {{ruleForm.phone}}
      </el-form-item>
      <el-form-item label=" 其它要求">
        {{ruleForm.mark_ingo}}
      </el-form-item>
      <el-button
        style="margin-left:300px;width:100px; margin-top:30px"
        type="primary"
        @click="returnback"
      >返回</el-button>
    </el-form>
  </div>

</template>

<script type="text/javascript">
// import this.$config from '../../../this.$config/this.$config'
export default {
  data() {
    return {
      options: [],
      options2: [],
      listDataBrand: [],

      provice: "",
      city: "",
      listDataSort: [],
      listData: [
        {
          label: 1,
          value: "自有品牌"
        },
        {
          label: 2,
          value: "代理"
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      ruleForm: {
        name: "",
        type: 1,
        cai_type: 1,
        cg_type: 1,
        phone: "",
        other_mark: "",
        spec: "",
        goodname: "",

        member_id: this.$store.state.member.id,
        member_name: this.$store.state.member.name,
        num: 0,

        mark_ingo: "",
        fh_mark: 1,
        fh_date: "",
        price_mark: 1,
        date_s: "",
        date_e: "",

        time: [],
        brand: "",
        brand_name: "",
        provice: "",
        provice_name: "",
        city: "",
        city_name: "",
        cate: "",
        cate_name: "",
        model: "",
        model_name: ""
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入标题",
            trigger: "blur"
          }
        ],
        provice: [
          {
            required: true,
            message: "选择所在地",
            trigger: "blur"
          }
        ],
        city: [
          {
            required: true,
            message: "选择所在地",
            trigger: "blur"
          }
        ],

        type: [
          {
            required: true,
            message: "请输入询价类型",
            trigger: "blur"
          }
        ],
        cg_type: [
          {
            required: true,
            message: "请输入商家筛选",
            trigger: "blur"
          }
        ],

        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur"
          },
          {
            pattern: this.$validate.mobile,
            message: "请填写正确的电话",
            trigger: "change"
          }
        ],

        cai_type: [
          {
            required: true,
            message: "请输入采购类型",
            trigger: "blur"
          }
        ],
        fh_date: [
          {
            required: true,
            message: "请输入发货时间要求（数字）",
            trigger: "blur"
          }
        ],

        fh_mark: [
          {
            required: true,
            message: "请填写发货要求",
            trigger: "blur"
          }
        ],
        price_mark: [
          {
            required: true,
            message: "请填写报价要求",
            trigger: "blur"
          }
        ],

        cate: [
          {
            required: true,
            message: "请选择品类",
            trigger: "blur"
          }
        ],
        model: [
          {
            required: true,
            message: "请选择经营模式",
            trigger: "blur"
          }
        ],

        // option: [
        //   {
        //     required: true,
        //     message: "请填写商家塞选",
        //     trigger: "blur"
        //   }
        // ],

        address: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur"
          }
        ],
        time: [
          {
            required: true,
            message: "请填写询价有效期截止时间",
            trigger: "blur"
          }
        ],
        goodname: [
          {
            required: true,
            message: "请填写产品名称",
            trigger: "blur"
          }
        ],
        brand: [
          {
            required: true,
            message: "请填写产品品牌",
            trigger: "blur"
          }
        ],

        num: [
          {
            required: true,
            message: "请填写需求数量（数字）",
            trigger: "blur"
          }
        ],

        spec: [
          {
            required: true,
            message: "请填写产品型号",
            trigger: "blur"
          }
        ]
      }
    };
  },
  filters: {},
  methods: {
    getSeek() {
      this.$get("seller/goodconsult/" + this.$route.query.id).then(res => {
        this.getProvince();
        console.log(" this.options", res.result);
        res.result.type = parseInt(res.result.type);
        res.result.cg_type = parseInt(res.result.cg_type);
        res.result.cai_type = parseInt(res.result.cai_type);
        res.result.model = parseInt(res.result.model);
        res.result.fh_mark = parseInt(res.result.fh_mark);
        // res.result.fh_date = parseInt(res.result.fh_date);
        res.result.price_mark = parseInt(res.result.price_mark);
        res.result.brand = parseInt(res.result.brand);
        res.result.provice = parseInt(res.result.provice);
        res.result.city = parseInt(res.result.city);
        res.result.num = parseInt(res.result.num);
        res.result.get_inventory = [res.result.get_inventory];

        if (res.result.cate) {
          res.result.cate = res.result.cate.split(",").map(a => parseInt(a));
        }

        this.getCity(res.result.city);
        this.ruleForm = res.result;
        this.ruleForm.time = [res.result.date_s, res.result.date_e];
      });
    },
    returnback() {
      this.$router.push("/member-seek");
    },
    // submitForm( ) {
    //   console.log(" this.ruleForm", this.ruleForm);

    //   let listDataBrand = this.listDataBrand;
    //   let listDataSort = this.listDataSort;
    //   let cate1 = "";
    //   let cate2 = "";
    //   let cate3 = "";
    //   //品牌名字
    //   this.ruleForm.brand_name = listDataBrand
    //     .filter(a => a.value == this.ruleForm.brand)
    //     .map(b => b.lable)
    //     .join(",");
    //   // 主营品类名字
    //   if (this.ruleForm.cate[0]) {
    //     //  cate1 = (listDataSort.filter(a => a.value == this.ruleForm.cate[0])).map(b => b.label).join(',')
    //     let a = listDataSort.filter(aa => aa.value == this.ruleForm.cate[0]);
    //     cate1 = a[0].label;
    //     this.ruleForm.cate_name = cate1;

    //     if (this.ruleForm.cate[1]) {
    //       let b = a[0].children.filter(bb => bb.value == this.ruleForm.cate[1]);
    //       console.log("b", b);
    //       cate2 = b[0].label;

    //       this.ruleForm.cate_name = cate1 + "/" + cate2;

    //       if (this.ruleForm.cate[2]) {
    //         let c = b[0].children.filter(
    //           cc => cc.value == this.ruleForm.cate[2]
    //         );
    //         cate2 = c[0].label;
    //         this.ruleForm.cate_name = cate1 + "/" + cate2 + "/" + cate3;
    //       }
    //     }
    //   }
    //   // 经营模式名字
    //   if (this.ruleForm.model == 1) this.ruleForm.model_name = "自有品牌";
    //   if (this.ruleForm.model == 2) this.ruleForm.model_name = "代理";
    //   // 省
    //   this.ruleForm.provice_name = this.options
    //     .filter(a => a.id == this.ruleForm.provice)
    //     .map(b => b.name)
    //     .join(",");
    //   // 市
    //   this.ruleForm.city_name = this.options2
    //     .filter(a => a.id == this.ruleForm.city)
    //     .map(b => b.name)
    //     .join(",");

    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       this.ruleForm.date_s = this.ruleForm.time[0];
    //       this.ruleForm.date_e = this.ruleForm.time[1];

    //       this.ruleForm.cate = this.ruleForm.cate.join(",");
    //       this.$post("home/goodconsult", this.ruleForm).then(res => {
    //         console.log(res);
    //         this.$message.success("发布成功");
    //         this.getSeek();
    //       });
    //     } else {
    //       return false;
    //     }
    //   });
    // },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getBrand() {
      this.$get("home/brand").then(res => {
        console.log("res11", res);
        this.listDataBrand = res.items.map(item => {
          return {
            lable: item.name,
            value: item.id
          };
        });
      });
    },
    getCategory() {
      //获取品类 listDataSort
      this.$get("home/goodsCategory").then(res => {
        res.forEach(a => {
          this.listDataSort.push({
            value: a.id,
            label: a.cate_name,
            children: a.cc.map(b => {
              return { value: b.id, label: b.cate_name };
            })
          });
        });
      });
    },
    getProvince() {
      this.$post("home/regions/index", {
        level: 1,
        pid: 0
      }).then(res => {
        this.options = res;
      });
    },
    getCity(isChange) {
      if (isChange) {
        this.ruleForm.city = undefined;

        this.options2 = [];
      }
      this.$post("home/regions/index", {
        level: 2,
        pid: this.ruleForm.provice
      }).then(res => {
        this.options2 = res;
      });
    }
  },
  components: {},
  created() {
    this.getBrand();
    this.getProvince();
    this.getCity();
    this.getCategory();
    this.getSeek();
  }
};
</script>

<style lang="scss" scoped>
.tab {
  margin-top: 10px;
  border-collapse: collapse;
  width: 100%;
  // .tr1 {
  //   td {
  //     background: #eee;
  //   }
  // }
  td {
    background: #fff;
    border: 1px solid #eee;

    text-align: center;
  }
}
@import "@/assets/styles/mixins";
// ::v-deep .el-form-item__label{ margin-right: 20px;
//   background: #eee;
//   display: block;height: 100%;
// }
::v-deep .el-form-item {
  margin-bottom: 2px;
}
::v-deep .el-form-item__content {
  padding-left: 20px;
}
.ruleForm {
  background: #fff;
  padding: 10px;
}
.titleNav {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #fff;
}
</style>
